<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.TAG_MANAGEMENT') }} - {{ $t('MANAGEMENT.CREATE_CHANGE_TAG') }}
    </p>
    <router-link :to="{name: 'SCTagManagement'}">
      <button class="primary-btn primary-btn_sm bg-yellow return-button">
        {{ $t("COMMON.RETURN") }}
        <img src="/assets/img/chevron-left-light.svg" alt="">
      </button>
    </router-link>
    <div class="sm-content">
      <div class="d-flex form-block">
        <div class="category-name px10">
          <label>{{ $t('MANAGE_PRODUCT.SELECT_CATEGORY') }}</label>
          <div class="icon-input">
            <input type="text" class="input" v-model="tagData.type" />
            <img src="/assets/img/icons/search-light.svg" alt="" />
          </div>
        </div>
        <div class="tag-name px10">
          <label>{{ $t('MANAGEMENT.TAG_NAME') }}</label>
          <input type="text" class="input" v-model="tagData.name" />
        </div>
        <div class="location px10">
          <label>{{ $t('DESTINATION.ORDER') }}</label>
          <input type="text" class="input" v-model="tagData.order" />
        </div>
        <div class="checkbox-div px20">
          <label>{{ $t('REPORT_SALES.STATUS') }}</label>
          <v-checkbox 
            color="#0D3856" 
            class="cus-checkbox" 
          ></v-checkbox>
        </div>
        <div class="checkbox-div px20">
          <label>{{ $t('MANAGEMENT.APPEARS_ON_PRODUCT') }}</label>
          <v-checkbox 
            color="#0D3856" 
            class="cus-checkbox" 
            v-model="tagData.show_on_product"
          ></v-checkbox>
        </div>
      </div>  
      <div class="d-flex justify-content-between m-t-30 px5">
        <div class="d-flex w-100">
          <button class="primary-btn primary-btn_sm" @click="updateTagData()">
            {{ $t("COMMON.SAVE") }}
          </button>
          <router-link :to="{name: 'SCTagManagement'}" class="mx10 bg-red2 primary-btn primary-btn_sm color-white">
              {{ $t("SUPPLIER_PAYMENT.CANCELATION") }}
          </router-link>
        </div>
      </div>
    </div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'SCTagEdit',
  data() {
    return {
      tagData: {}
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.tag_management.loading,
      tags: state => state.tag_management.tags,
    }),
    tagId() {
      return this.$route.params.id;
    }
  },
  async created() {
    if (this.tags.length == 0) {
      await this.getTags();
    }
    if (this.tags.length != 0) {
      this.tagData = this.tags.filter((tag) => {
        return this.tagId == tag.id;
      })[0]
    } 
  },
  methods: {
    ...mapActions('tag_management', {
      getTags: 'getTags',
      updateTag: 'updateTag'
    }),
    updateTagData() {
      const id = this.tagId;
      this.updateTag({id: id, params: this.tagData});
    }
  }
}
</script>

<style lang="scss" scoped>
.return-button {
  position: absolute;
  left: 30px;
  top: 40px;
  min-width: unset;
  width: auto;
  padding: 0 30px;
  img {
    position: absolute;
    left: 10px;
    height: 15px;
  }
}
.form-block {
  .category-name {
  width: 25%;
  }
  .tag-name {
    width: 25%;
  }
  .location {
    width: 15%;
  }
  .checkbox-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .return-button {
    top: 10px;
  }
  .form-block {
    flex-wrap: wrap;
    .category-name,
    .tag-name,
    .location {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
</style>